import revive_payload_client_AIofky8nMiQBFUlwhmFzuZqe3NYZmLKTTme6jER3vVQ from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xbkmAN2SclgO9hg8Us0M3ioTvW8m5I_5UxtYY9tG630 from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rsUpm0SI7l5buaBtZ9p8S6pLyr4NMQye8BBMbCfj68s from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_eaOgd1zUPls7TlIhdDRW4BWWyKTleSY2y4FtGQJN2uc from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bXT0INoAPEwRNjMgKgxSd1HnJLGsXuXpnL9P3mCY8fM from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xiFDG11BO1VCGKB2qYO3MJOpvpa6H7kUvS4VkZzl_bs from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ECFvVvFPeIhCzX9gXCBARNO7kHrLRyy_ZLlq9_gmrpg from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import preview_client_7c5UGmEdJv_7rWSVU6znYUCcDZYjs7gwCyQXfXqC9Pk from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxt+content@https+++pkg.pr.new+@nuxt+content@ef50925_patch_hash=87785a9b1fbf4e01ba1ae_481a833140f600966d8eff97f055ca95/node_modules/@nuxt/content/dist/runtime/plugins/preview.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_MzNVQiLo7MyQhWvpBJt51QtlF9Wwm0KJR5kUBAfJ_jc from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_bp5cy04bRzkBYeRKp0omeTqJ93AQ8ePWje3kt_xI07A from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt-content-twoslash@0.1.2_@nuxtjs+mdc@0.16.1_magicast@0.3.5__magicast@0.3.5/node_modules/nuxt-content-twoslash/dist/runtime/plugin.js";
import colors_rkPck3ngrT1EZ3rtCSokF65pNC_sS0n0JO8vqpC5QHE from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxt+ui@3.0.2_@babel+parser@7.26.10_change-case@5.4.4_db0@0.3.1_better-sqlite3@11.9.1__c23b16b5d23d07dc7f4d420cbcd343ad/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Yer9A6_GIriawcWuStDGoLPAO9hCXj0X1J1b9yz8mUE from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_lightningcs_7ac649dc72d4b5286c141c4117fbb215/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import adblock_izpFnT3Eij5ts9JY8vtccDixFrEm2vcq743yesZvBPE from "/home/runner/work/nuxt.com/nuxt.com/app/plugins/adblock.ts";
import components_oeO00s_xUxei9uzRPGtSyRCUnNlAU8LGKrcJZd0zP08 from "/home/runner/work/nuxt.com/nuxt.com/app/plugins/components.ts";
import kapa_client_bmXANadq9AJQvkaT5dG0IOargcdl6jkVCb3TSGiHa0Q from "/home/runner/work/nuxt.com/nuxt.com/app/plugins/kapa.client.ts";
import newsletter_client_q4JThTqQWaBOqfCGgA9b_FbT2fqJqTxEISvWypUBzHE from "/home/runner/work/nuxt.com/nuxt.com/app/plugins/newsletter.client.ts";
import stats_NR2hKfEAJyMK_xxzAAoSnnnFV8dSSAI6Z4FoGpQjLhA from "/home/runner/work/nuxt.com/nuxt.com/app/plugins/stats.ts";
import plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_AIofky8nMiQBFUlwhmFzuZqe3NYZmLKTTme6jER3vVQ,
  unhead_xbkmAN2SclgO9hg8Us0M3ioTvW8m5I_5UxtYY9tG630,
  router_rsUpm0SI7l5buaBtZ9p8S6pLyr4NMQye8BBMbCfj68s,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_eaOgd1zUPls7TlIhdDRW4BWWyKTleSY2y4FtGQJN2uc,
  navigation_repaint_client_bXT0INoAPEwRNjMgKgxSd1HnJLGsXuXpnL9P3mCY8fM,
  check_outdated_build_client_xiFDG11BO1VCGKB2qYO3MJOpvpa6H7kUvS4VkZzl_bs,
  chunk_reload_client_ECFvVvFPeIhCzX9gXCBARNO7kHrLRyy_ZLlq9_gmrpg,
  preview_client_7c5UGmEdJv_7rWSVU6znYUCcDZYjs7gwCyQXfXqC9Pk,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_MzNVQiLo7MyQhWvpBJt51QtlF9Wwm0KJR5kUBAfJ_jc,
  plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc,
  plugin_bp5cy04bRzkBYeRKp0omeTqJ93AQ8ePWje3kt_xI07A,
  colors_rkPck3ngrT1EZ3rtCSokF65pNC_sS0n0JO8vqpC5QHE,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_Yer9A6_GIriawcWuStDGoLPAO9hCXj0X1J1b9yz8mUE,
  adblock_izpFnT3Eij5ts9JY8vtccDixFrEm2vcq743yesZvBPE,
  components_oeO00s_xUxei9uzRPGtSyRCUnNlAU8LGKrcJZd0zP08,
  kapa_client_bmXANadq9AJQvkaT5dG0IOargcdl6jkVCb3TSGiHa0Q,
  newsletter_client_q4JThTqQWaBOqfCGgA9b_FbT2fqJqTxEISvWypUBzHE,
  stats_NR2hKfEAJyMK_xxzAAoSnnnFV8dSSAI6Z4FoGpQjLhA,
  plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs
]