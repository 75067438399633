import routerOptions0 from "/home/runner/work/nuxt.com/nuxt.com/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.1_db0@0.3.1__4af97628204ee5e444903c6101da3e0a/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/nuxt.com/nuxt.com/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "smooth"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}