import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAmplify, LazyNetlify, LazyNuxtHub, LazyVercel, LazyProseA, LazyProseAccordion, LazyProseAccordionItem, LazyProseBadge, LazyProseBlockquote, LazyProseCallout, LazyProseCard, LazyProseCardGroup, LazyProseCode, LazyProseCodeCollapse, LazyProseCodeGroup, LazyProseCodeIcon, LazyProseCodePreview, LazyProseCodeTree, LazyProseCollapsible, LazyProseEm, LazyProseField, LazyProseFieldGroup, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseHr, LazyProseIcon, LazyProseImg, LazyProseKbd, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseSteps, LazyProseStrong, LazyProseTable, LazyProseTabs, LazyProseTabsItem, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl, LazyProseCaution, LazyProseNote, LazyProseTip, LazyProseWarning, LazyProseH5, LazyProseH6, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Amplify", LazyAmplify],
["Netlify", LazyNetlify],
["NuxtHub", LazyNuxtHub],
["Vercel", LazyVercel],
["ProseA", LazyProseA],
["ProseAccordion", LazyProseAccordion],
["ProseAccordionItem", LazyProseAccordionItem],
["ProseBadge", LazyProseBadge],
["ProseBlockquote", LazyProseBlockquote],
["ProseCallout", LazyProseCallout],
["ProseCard", LazyProseCard],
["ProseCardGroup", LazyProseCardGroup],
["ProseCode", LazyProseCode],
["ProseCodeCollapse", LazyProseCodeCollapse],
["ProseCodeGroup", LazyProseCodeGroup],
["ProseCodeIcon", LazyProseCodeIcon],
["ProseCodePreview", LazyProseCodePreview],
["ProseCodeTree", LazyProseCodeTree],
["ProseCollapsible", LazyProseCollapsible],
["ProseEm", LazyProseEm],
["ProseField", LazyProseField],
["ProseFieldGroup", LazyProseFieldGroup],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseHr", LazyProseHr],
["ProseIcon", LazyProseIcon],
["ProseImg", LazyProseImg],
["ProseKbd", LazyProseKbd],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseSteps", LazyProseSteps],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTabs", LazyProseTabs],
["ProseTabsItem", LazyProseTabsItem],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
["ProseCaution", LazyProseCaution],
["ProseNote", LazyProseNote],
["ProseTip", LazyProseTip],
["ProseWarning", LazyProseWarning],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
